import "./TransactionWidget.scss";
import React from "react";
import {
    canShowCopyToClipboard,
    copyToClipboard,
    numberToMoneyFormat,
} from "../components/helper";
import { ItemModel } from "../models/ItemModel";
import { TransactionModel } from "../models/TransactionModel";
import { FormattedMessage } from "react-intl";

type TransactionProperties = {
    transaction: any;
};

export class TransactionWidget extends React.Component<TransactionProperties> {
    private transaction: TransactionModel;

    componentDidUpdate() {
        if (
            this.transaction.bill_total !== this.props.transaction.bill_total ||
            this.transaction.payment_channel_id !==
                this.props.transaction.payment_channel_id
        ) {
            this.transaction.bill_total = this.props.transaction.bill_total;
            this.transaction.payment_channel_id =
                this.props.transaction.payment_channel_id;
            this.setState({});
        }
    }

    constructor(props: any) {
        super(props);
        this.transaction = new TransactionModel(props.transaction);
    }

    renderTransactionStatus = (status: string) => {
        var badge: string = "badge badge-";
        switch (status) {
            case "PAID":
                badge += "success";
                break;
            case "WAITING":
                badge += "warning";
                break;
            case "CANCELED":
            case "EXPIRED":
                badge += "danger";
                break;
            default:
                badge += "secondary";
        }
        // khusus VA
        let statusLabel = status;
        if (this.transaction.payment_channel_id === 5) {
            statusLabel = this.transaction.payment_receipt_url
                ? "WAITING CONFIRMATION"
                : "WAITING PAYMENT";
        }
        return <small className={badge}>{statusLabel}</small>;
    };

    renderTransactionItems = (details: ItemModel[]) => {
        return (
            <div>
                {details.map((detail: ItemModel) => {
                    return (
                        <div key={detail.name} className="mb-2">
                            <b>{detail.name}</b>
                            <br />
                            {!!detail.notes && (
                                <>
                                    <small>{detail.notes}</small>
                                    <br />
                                </>
                            )}
                            <small>
                                {detail.qty} item x Rp.{" "}
                                {numberToMoneyFormat(detail.price)}
                            </small>
                        </div>
                    );
                })}
            </div>
        );
    };

    renderBillTotal() {
        if (this.transaction.payment_channel_id) {
            return (
                <div
                    onClick={() => {
                        copyToClipboard(this.transaction.bill_total.toString());
                    }}
                >
                    <div className="bill-total">
                        Rp. {numberToMoneyFormat(this.transaction.bill_total)}
                    </div>
                    {canShowCopyToClipboard() && (
                        <div
                            className="text-right"
                            style={{ marginTop: "-10px" }}
                        >
                            SALIN
                        </div>
                    )}
                </div>
            );
        } else {
            return (
                <b>
                    <FormattedMessage
                        id="selectPaymentMethod"
                        defaultMessage={"Silahkan pilih metode pembayaran"}
                    />
                </b>
            );
        }
    }

    renderPaymentInfo() {
        if (this.transaction.status === "PAID") {
            return <div></div>;
        } else {
            return (
                <div>
                    <span className="float-left">Payment Limit:</span>
                    <span className="float-right">
                        <b>
                            {this.transaction.expired_at?.format(
                                "D MMM YYYY HH:mm"
                            )}
                        </b>
                    </span>
                    <div className="clearfix"></div>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="card mb-1">
                <div className="card-body">
                    <div>
                        <div className="float-left">
                            <small>{this.transaction.bill_no}</small>
                            <br />
                            <small>
                                {this.transaction.bill_at.format(
                                    "D MMM YYYY HH:mm"
                                )}
                            </small>
                        </div>
                        <span className="float-right">
                            {this.renderTransactionStatus(
                                this.transaction.status
                            )}
                        </span>
                    </div>
                    <div className="clearfix"></div>
                    <hr />
                    {this.renderTransactionItems(this.transaction.items)}
                    <hr />
                    <div>
                        {this.transaction.misc_fee &&
                            this.transaction.misc_fee < 0 && (
                                <div>
                                    <span className="float-left">
                                        <div>
                                            <FormattedMessage
                                                id="discount"
                                                defaultMessage={"Potongan"}
                                            />
                                            :
                                        </div>
                                    </span>
                                    <span className="float-right">
                                        Rp.{" "}
                                        {numberToMoneyFormat(
                                            this.transaction.misc_fee
                                        )}
                                    </span>
                                </div>
                            )}
                        {this.transaction.misc_fee && (
                            <div>
                                <span className="float-left">
                                    <div>
                                        <FormattedMessage
                                            id="additionalFee"
                                            defaultMessage={"Biaya Lain-lain"}
                                        />
                                        :
                                    </div>
                                </span>
                                <span className="float-right">
                                    Rp.{" "}
                                    {numberToMoneyFormat(
                                        this.transaction.misc_fee
                                    )}
                                </span>
                            </div>
                        )}

                        <div className="clearfix"></div>
                        <div>
                            <span className="float-left">
                                <div
                                    className={
                                        this.transaction.payment_channel_id
                                            ? "bill-total"
                                            : ""
                                    }
                                    style={{
                                        paddingTop: this.transaction
                                            .payment_channel_id
                                            ? "1px"
                                            : "inherit",
                                    }}
                                >
                                    Total:
                                </div>
                            </span>
                            <span className="float-right">
                                {this.renderBillTotal()}
                            </span>
                        </div>
                        <div className="clearfix"></div>
                        {this.transaction.payment_channel_id === 5 && (
                            <div
                                className="text-right mb-3"
                                style={{ fontSize: "0.9em" }}
                            >
                                <i>*wajib mengirimkan sesuai kode unik</i>
                            </div>
                        )}
                        {this.renderPaymentInfo()}
                    </div>
                </div>
            </div>
        );
    }
}
